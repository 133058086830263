.listMaxWidth{
    max-width: 300px;
    width: 300px;
    padding-left: 18px;
}
.listMaxWidth1{
    padding-left: 18px;
}
.finalPrice .MuiListItemText-primary{
    color: green;

}

.bold{
    font-weight: 500;
}

.drop{
    width: 100%;
    height: 100px;
    text-align: center;
}

.imgCrop{
    width: 60px;
    height: 60px;
    -webkit-border-radius: 60px;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 60px;
    -moz-background-clip: padding;
    border-radius: 60px;
    background-clip: padding-box;
    margin: 7px 0 0 5px;
    float: left;
    background-size: cover;
    background-position: center center;
}

.SignInPopOver .popover {
    max-width: 400px !important;
    max-height: 425px;
    background-color: #fafafa;
  }