.ellipsis{
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

  .sellOnMedideals {
    /* background-color: #fff; */
    margin-bottom: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #072791;
    padding-left: 25px;
  }
  
  @media only screen and (max-width: 763px) {
    .secondaryHeaderWrapper{
    align-content: center;
    height: 36px;
    margin-bottom: 20px;
    }
    .secondaryHeaderMain {
    height: 50px;
    display: flex;
    overflow-y: auto;
    }
    .dealsOfTheDay{
    background-color: #dddddd;
    color: #072791;
    font-weight: 500;
    width: 500px !important;
    }
    .capsuleTwo{
    border-radius: 15px;
    border: 2px solid #dddddd;
    padding: 5px;
    width: 130px;
    display: block;
    height: 32px;
    text-align: center;
    }
    .capsuleTwoMaxLength{
    width: 180px;
    }
    .sellOnMedideals {
  margin-bottom: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #072791;
  padding-left: 25px;
  white-space: nowrap;
  display: flex;
  align-items: center; /* Aligns items vertically centered if necessary */
  background-color: #dddddd;
  }
  }

  .dealsOfTheDay{
    /* background-color: #fff; */
    margin-bottom: 0px;
    padding-left: 25px;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #072791;
    font-weight: 500;
  }
  
  .ethicalBranded{
    /* background-color: #fff; */
    margin-bottom: 0px;
    padding-left: 25px;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #ff7000;
    font-weight: 500;
  }

.generic{
  /* background-color: #fff; */
  margin-bottom: 0px;
  padding-left: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #038d0e;
  font-weight: 500;
}

.coolChain{
  /* background-color: #fff; */
  margin-bottom: 0px;
  padding-left: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #0b68a8;
  font-weight: 500;
}

.capsuleEthicalBranded{
  border-radius: 15px;
  border: 2px solid #dddddd;
  padding: 5px;
}

.capsuleEthicalBranded:hover{
  border-radius: 15px;
  border: 2px solid #ff7000;
  padding: 5px;
}

.capsuleGeneric{
  border-radius: 15px;
  border: 2px solid #dddddd;
  padding: 5px;
}

.capsuleGeneric:hover{
  border-radius: 15px;
  border: 2px solid #038d0e;
  padding: 5px;
}

.capsuleCoolChain{
  border-radius: 15px;
  border: 2px solid #dddddd;
  padding: 5px;
}

.capsuleCoolChain:hover{
  border-radius: 15px;
  border: 2px solid #0b68a8;
  padding: 5px;
}

.onHover:hover{
  border-bottom: 2px solid currentColor
}


.capsule{
  /* border: 1px solid; */
  padding: 5px 10px;
  border-radius: 15px;
  background-color: #072791;
  color: white !important;
  margin-right: 10px;
}

.capsuleTwo{
  border-radius: 15px;
  border: 2px solid #dddddd;
  padding: 5px;
}

.capsuleTwo:hover{
  border-radius: 15px;
  border: 2px solid #072791;
  padding: 5px;
}

.noShadow {
  box-shadow: none !important;
}

.phone {
  color: #878787;
}

.navbar--hidden {
  top: -50px;
}

/* .signInPadding{
  padding-left: 24% !important;
} */

/* .list-inline .signInPadding {
  margin-left: 25px;
} */

.secondaryHeaderMain {
  width: 100% !important;
  /* display: flex;  */
  /* background-color: #fff; */
}

/* .secondaryHeaderWrapper .secondaryHeaderMain{
  padding-left: 500px !important;
  padding-right: 500px !important;
  width: 100% !important;
} */

/* @media only screen and (min-width: 1400px) {
  .secondaryHeaderWrapper .secondaryHeaderMain {
    padding-left: 0px !important;
    padding-right: 0px !important;
    width: 100% !important;

  }
}*/

.scrollBehavior{
  scroll-behavior: smooth;
}

.secondaryHeaderWrapper{
  align-content: center;
  background-color: #dddddd;
  height: 36px;
}

/* For both UserProfileCard and ProductForYouCard */
.itemImage{
  width: 100%;
  height: auto;
  margin: 0 auto;
}

@media screen and (max-width: 480px) {
  .itemImage{
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .arrows{
      height: 20px;
    margin: auto;
  }}


.home_banner .carousel-inner .carousel-item-start {
  transform: none !important;
}