li.putIconAfter.Mui-selected:after{
    content: '\e876';
    display: inline-block;
    font-family: 'Material Icons';
    src: url(https://example.com/MaterialIcons-Regular.eot); /* For IE6-8 */
  src: local('Material Icons'),
    local('MaterialIcons-Regular'),
    url(https://example.com/MaterialIcons-Regular.woff2) format('woff2'),
    url(https://example.com/MaterialIcons-Regular.woff) format('woff'),
    url(https://example.com/MaterialIcons-Regular.ttf) format('truetype');
    width: 100%;
    text-align: end;
    color: green;
    font-weight: 800;
}


.categoryFilter .form-control {
  margin-top: 8px !important;
}