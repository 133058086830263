.newfooter{
    min-height: 300px !important;
    height: 300px !important;
    max-height: 300px !important;
}

.liststyle{
    list-style-type:none;
}

.rowstyle{
    width: 283px;
}

.approw{
    margin-top: -55px;
    margin-left: 103px;
}

.appStyle{
    margin-top:-86px ;
}

@media only screen and (max-width:693px) {
    .approw {
      display: none;
    }
    .newfooter{
        min-height: 600px !important;
        height: 600px !important;
        max-height: 600px !important;
    }
  }


.textLeft{
   margin-right: 4px !important; 
   color: black;
}

.footText{
    margin-top: 55px !important
}

ul li {
    border-bottom: 0px !important;
    list-style-type: none;
}

.listDots ul li{
    list-style-type: initial !important;
}