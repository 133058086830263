.fa-angry:hover {
  color: #fa163f;
}
.fas.fa-angry{
  color: #fa163f;
}
.fas.fa-grin-hearts{
  color: #eb4d55;
}
.fas.fa-smile-beam{
  color:  #4CAF50;
}
.fas.fa-meh{
  color: #40bfc1;
}
.fas.fa-sad-tear{
  color: #fddb3a;
}
.fa-sad-tear:hover {
  color: #fddb3a;
}
.fa-meh:hover {
  color: #40bfc1;
}
.fa-smile-beam:hover {
  color:  #4CAF50;
}
.fa-grin-hearts:hover {
  color: #eb4d55;
}