/* 
.sellOnMedideals{
    background-color: #fff;
    margin-bottom: 0px;
    padding-left: 100px;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #072791;
  } */
  
  .noShadow{
    box-shadow: none !important;
  }

  .phone{
      color: #878787;
  }

  .navbar--hidden {
    top: -50px;
  }

  .signin {
    margin-left: 0 !important;
    background-color: #072791 !important;
  }