.listMaxWidth{
    max-width: 300px;
    width: 300px;
}
.finalPrice .MuiListItemText-primary{
    color: green;

}

/* .orderScroll{
    height: 770px; 
    overflow-y: scroll;
    overflow-x: hidden; 
} */

.SignInPopOver .popover {
    max-width: 400px !important;
    max-height: 425px;
    background-color: #fafafa;
  }