.avatar {
  margin: 0;
}

.button {
  margin: 5px !important;
}

.theme-option {
  display: none;
}

.topSellingProducts {
  padding: 0;
}

.badge {
  font-size: 13px;
}

a:hover {
  text-decoration: none;
}

.editProfile {
  padding-bottom: 10px;
}

.customButton {
  padding: 15px;
  text-align: right;
}

.signinContainer {
  text-align: -webkit-center;
  margin: auto;
  display: block
}

.signinCard {
  width: auto;
}

.m-2 {
  float: left;
}

@media only screen and (min-width:320px) and (min-width:480px) and (min-width:600px) and (min-width:801px) {
  .signinCard {
    width: 50%;
  }
}

.verifyTokenContainer {
  text-align: -webkit-center;
  margin: auto;
  display: block
}

.verifyTokenCard {
  width: auto;
}

@media only screen and (min-width:320px) and (min-width:480px) and (min-width:600px) and (min-width:801px) {
  .verifyTokenCard {
    width: 50%;
  }
}

.forgotPasswordContainer {
  text-align: -webkit-center;
  margin: auto;
  display: block
}

.forgotPasswordCard {
  width: auto;
}

@media only screen and (min-width:320px) and (min-width:480px) and (min-width:600px) and (min-width:801px) {
  .forgotPasswordCard {
    width: 50%;
  }
}

.button {
  padding: 0 12px !important;
}

.user-complaince-check {
  color: white !important;
  box-shadow: unset !important;
  width: 25px !important;
  height: 25px !important;
  background-color: #4caf50 !important;
  min-height: auto !important;
}

.user-complaince-gst {
  color: white !important;
  box-shadow: unset !important;
  width: 25px !important;
  height: 25px !important;
  background-color: #072791 !important;
  min-height: auto !important;
}

.user-complaince-FSSI {
  color: white !important;
  box-shadow: unset !important;
  width: 25px !important;
  height: 25px !important;
  background-color: #072791 !important;
  min-height: auto !important;
}

.user-name {
  margin-top: 8px;
}

.complaince-button {
  margin-left: 10px !important;
  background-color: #4caf50 !important;
  color: white !important;
}

.complaince-button-gst {
  margin-left: 10px !important;
  background-color: #072791 !important;
  color: white;
  white-space: nowrap;
}

.complaince-button-FSSI {
  margin-left: 10px !important;
  background-color: #072791 !important;
  color: white;
  white-space: nowrap;
}

.complaince-datePicker {
  margin-left: 30px !important;
  margin-top: 0 !important;
}

.filterButton {
  margin-right: 10px !important;
  background-color: #072791 !important;
}

.orderListButton {
  padding: 0 12px !important;
}

.rating {
  font-size: 20px;
}

.rating1 {
  font-size: 15px;

}

.ratingSmileEmoji {
  font-size: 25px;
  height: 30px;
}

.ratingSpan {
  color: #000000 !important;
  font-size: 13px !important;
}

.ratingIcon {
  text-align: center;
  font-size: 20px !important;
}

.ratingDanger {
  color: #f44336 !important;
  font-size: 30px;
}

.ratingSuccess {
  color: #4caf50 !important;
  font-size: 30px !important;
}

.trackOrderID {
  font: bold;
  float: left;
}

.trackOrderDate {
  font: bold;
  float: right;
}

.timeline-section {
  padding-top: 15px
}

.user-detail-name {
  padding-left: 10px;
}

.nav-text {
  font-size: 17px;
  /* color : #252525; */
}

.indigo ul.nav-menu li a {
  color: #252525;
}

.user-detail h4 {
  font-size: 17px !important;
  color: #252525 !important;
}

.app-logo img {
  height: 90px;
}

.medilogo {
  object-fit: contain;
  width: 150px;
  height: auto;
  bottom: 0;
}

.medilogo-container {
  padding: 10px;
}

.signin {
  margin-left: 0 !important;
  background-color: #072791 !important;
}

.groupButton {
  border: 1px solid #3f51b580 !important;
  border-radius: 0 !important;
}

#filterReset-button {
  display: none !important;
}

.addGroupButton {
  align-self: flex-end !important;
}

.addStaffAvatar {
  text-align: -webkit-center;
  padding: 23px;
}

.dashboardInvoice {
  justify-content: flex-end !important;
}

.vacationMode {
  padding: 7px 0px 8px 20px !important;
  align-items: center !important;
  color: #252522 !important;
  font-size: 14px !important;
  min-width: inherit !important;
}

.vacationToggle {
  margin-left: 61px !important;
}

.vacationDatePicker {
  width: 100% !important;
}

.vacationDate {
  color: #072791 !important;
}

.indigo ul.nav-menu > li.open > a {
  color: #ffffff;
  background-color: #072791;
  border-top-right-radius: 1.875rem;
  border-bottom-right-radius: 1.875rem;
  margin-right: 20px;
  color: white;
}
.sellerSideBarModal{
  min-width: 600px !important;
  min-height: 100px !important;
}

.img-wrap {
  position: relative;
  display: inline-block;
  border: none;
  font-size: 0;
}

.img-wrap .close {
  position: absolute;
  top: -18px;
  right: -18px;
  z-index: 100;
  background-color: #FFF;
  padding: 5px 2px 2px;
  color: #000;
  font-weight: bold;
  cursor: pointer;
  opacity: .2;
  text-align: center;
  font-size: 22px;
  line-height: 10px;
  border-radius: 50%;
}
.img-wrap:hover .close {
  opacity: 1;
}
.liCNo div input{
  text-align: center !important;
}
.liCNo{
  margin-top: 0px !important;
}

/* .img-fluid {
  height: 185px;
  width: 185px;
} */

.UnderMaintenance {
  width: 550px;
  height: 300px;
}

@media only screen and (max-width: 600px) {
  .UnderMaintenance {
    width: 330px;
    height: 270px;
  }
}

.maintenanceImage{
  margin-bottom: 60px;
  margin-top: 35px;
  width:40%; 
}

@media only screen and (max-width: 600px) {
  .maintenanceImage {
    margin-bottom: 40px;
    margin-top: 20px;
    width:70%; 
  }
}

.bg-pink{
  background-color: #c528c7;
}
/* .AboutUs {
  width: 750px;
  height: 400px;
} */
.scrollbar{
  overflow: hidden !important;
}