@import url("https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");

.carousel-control-next-icon, .carousel-control-prev-icon {
  width: 35px !important;
  height: 35px !important;
  border-radius: 50% !important;
}

.carousel-control-next {
  justify-content: flex-end;
}

.carousel-control-prev {
  justify-content: flex-start
}

.product-item {
  box-shadow: -5px 5px 4px 0 rgba(0, 0, 0, 0.2) !important;
}

.cursor-pointer {
  cursor: pointer;
}

.backgroundCover {
  height: 450px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.btnPrimary {
  background-color: #072791 !important;
  color: white !important;
}

@media only screen and (min-width:100px) {
  .app-logoB img {
    width: 100px;
    height: auto;
  }
}

@media only screen and (min-width:693px) {
  .app-logoB img {
    width: 170px;
    height: auto;
  }
}

.app-header-horizontal .app-toolbar .search-bar {
  margin-left: 0px !important;
  max-width: 100% !important;
  width: 100% !important;
}

.iconAvtarColor {
  width: 22px !important;
  height: 22px !important;
  margin-right: -1px !important;
  margin-left: -3px !important;
}

.slick-track {
  margin-bottom: 9px !important;
}

.app-notification-menu .icon {
  font-size: 20px !important;
}

.dropDown {
  /* padding-left: 24% !important; */
  display: flex;
  align-self: center;
}

.cartIcon {
  text-align: end !important;
  /* display: flex; */
  align-self: center;
}

@media only screen and (min-width:801px) {
  .paddingZero {
    padding: 0px !important;
  }
}

.shopping-cart {
  font-size: large;
}

.carousel-control-next-icon {
  background-image: url('assets/images/next.png') !important;
}

.carousel-control-prev-icon {
  background-image: url('assets/images/back.png') !important;
}

.dashboardCategory {
  padding: 10px !important;
}


.dashboardCategory {
  padding: 10px !important;
}

.card-titleIntranet {
  font-size: 18px !important;
  margin-bottom: 5px !important;
}
  @media screen and (max-width: 480px) {
.card-titleIntranet {
  font-size: 18px !important;
}
  }
.card-footer:last-child {
  border-radius: 0 0 calc(.375rem - 1px) calc(.375rem - 1px);
}

.intranetFooter {
  border-top: 1px solid #daddf1 !important;
  margin-bottom: 10px !important;
  padding: 30px !important;
  position: relative !important;
  white-space: nowrap !important;
  min-height: 100px;
  max-height: 120px;
}

.intranetFooterSeller {
  border-top: 1px solid #daddf1 !important;
  position: relative !important;
  white-space: nowrap !important;
}

/* .categoryCard {
  width: -100px !important;
} */

@media only screen and (min-width:320px) and (min-width:480px) and (min-width:600px) {
  .categoryCard {
    margin-top: -100px!important;
  }
}

.originalPrice {
  color: #6c757d !important;
  /* #B12704!important; */
  text-decoration: line-through;
  font-weight: bold;
}

.originalPriceSeller {
  color: #6c757d !important;
  /* #B12704!important; */
  /* text-decoration: line-through; */
  font-weight: bold;
}

.font {
  font-size: 12px!important;
  line-height: 19px!important;
}

.priceColor {
  color: #4caf50!important;
  font-size: 20px!important;
  line-height: 19px!important;
  /* font-weight: bold; */
}

.priceColorInventory {
  color: #4caf50!important;
  font-size: 17px!important;
  line-height: 19px!important;
}

.discountPrice {
  color: #555!important;
  font-size: 12px!important;
  line-height: 1.255!important;
}

@media only screen and (max-width:480px) {
  .itemImage {
    height: 100px !important;
  }
}

.buyerEditProfile {
  justify-content: flex-end !important;
  display: flex !important;
}

@media only screen and (max-width:480px) {
  .profileTab {
    font-size: .75rem !important;
  }
  .cartIcon {
    justify-content: flex-end !important;
    /* display: flex; */
    align-self: center;
  }
}

.ptrPriceFont {
  padding-left: 6px;
  color: #4caf50!important;
}

.ptrPriceFont1 {
  /* padding-left: 6px; */
  /* font-weight: bold; */
  color: #4caf50!important;
  /* padding-left: 5px; */
}

.buyerRedirectNavlink {
  color: #6c757d !important;
  text-decoration: none;
}

.productImageGrid {
  padding-bottom: 30px !important;
}

.productImage {
  padding: 0px 5px !important;
}

.slickSlider {
  margin: 0 -10px 0 -10px !important;
}

.content {
  width: 46vw;
  height: 46vw;
}

.sliderImageDiv {
  min-height: auto;
  padding: 0;
}

@media only screen and (min-width:769px) and (max-width:1024px) {
  .content {
    width: 45.5vw;
    height: 45.5vw;
  }
  .itemImageProductDetail {
    height: 125px !important;
  }
}

@media only screen and (min-width:600px) and (max-width:768px) {
  .content {
    width: 44.5vw;
    height: 44.5vw;
  }
  .sliderImageDiv {
    width: 25%;
  }
}

@media only screen and (min-width:1400px) and (max-width:1700px) {
  .content {
    width: 32vw;
    height: 32vw;
  }
  .sliderImageDiv {
    width: 25%;
  }
}

@media only screen and (min-width:1700px) and (max-width:1900px) {
  .content {
    width: 34vw;
    height: 34vw;
  }
  .sliderImageDiv {
    width: 25%;
  }
}

@media only screen and (min-width:1900px) {
  .content {
    width: 36vw;
    height: 36vw;
  }
  .sliderImageDiv {
    width: 25%;
  }
}

.dashboardCategory {
  padding: 10px !important;
}

.intranetCard .card-title {
  font-size: 20px !important;
  margin-bottom: 10px !important;
}

.dashboardCategory {
  padding: 10px !important;
}

.card-titleIntranet {
  font-size: 18px !important;
  margin-bottom: 5px !important;
}

.card-footer:last-child {
  border-radius: 0 0 calc(.375rem - 1px) calc(.375rem - 1px);
}

@media only screen and (min-width:576px) and (max-width:600px) {
  .content {
    width: 41.5vw;
    height: 41.5vw;
  }
  .sliderImageDiv {
    width: 25%;
  }
}

@media only screen and (min-width:415px) and (max-width:575px) {
  .content {
    width: 90.3vw;
    height: 90.3vw;
  }
  .sliderImageDiv {
    width: 25%;
  }
}

@media only screen and (min-width:376px) and (max-width:414px) {
  .content {
    width: 90vw;
    height: 90vw;
  }
  .sliderImageDiv {
    width: 25%;
  }
  .itemImageProductDetail {
    height: 100px !important;
  }
}

@media only screen and (max-width:375px) {
  .content {
    width: 89vw;
    height: 89vw;
  }
  .sliderImageDiv {
    width: 25%;
  }
  .itemImageProductDetail {
    height: 100px !important;
  }
}

.app-toolbar {
  padding: 15px 0 15px 30px;
}
.app-toolbarB {
  padding: 15px 0 15px 30px;
  background-color: white;
  height: 70px;
  /* box-shadow: 0px 5px 10px #888888; */
}

.mobileview-app-toolbar {
  padding: 10px;
  background-color: white;
  /* box-shadow: 0px 5px 10px #888888; */
}

.jr-comments .media-list:hover {
  /* background-color: #e4e7f6; */
  background-color: white;
}

.thumbsUp:hover {
  color: #4caf50;
}

.thumbsDown:hover {
  color: red;
}

.itemImageProductDetail .carousel-control-next {
  width: 4% !important;
}

.itemImageProductDetail .carousel-control-prev {
  width: 4% !important;
}

.priceColorProduct {
  color: #4caf50!important;
  font-size: 35px!important;
  line-height: 19px!important;
}

.originalPriceProduct {
  color: #6c757d !important;
  /* #B12704!important; */
  text-decoration: line-through;
  font-size: 18px!important;
}

.sellerRatingAvatar {
  text-align: -webkit-right !important;
}

.tab-custom {
  font-size: 1.25rem !important;
  font-weight: 500 !important;
  line-height: 1.6 !important;
  letter-spacing: 0.0075em !important;
  color: #252525 !important;
}

.ratingSmallIcon {
  width: 30px !important;
  height: 30px !important;
  background-color: #2caf50 !important;
}

.ratingSmallIconDanger {
  width: 30px !important;
  height: 30px !important;
  background-color: #f44336 !important;
}

.ratingSmallIcon1 {
  width: 25px !important;
  height: 25px !important;
  background-color: #2caf50 !important;
}

.ratingSmallIconDanger1 {
  width: 25px !important;
  height: 25px !important;
  background-color: #f44336 !important;
}

.reviewSellerCard {
  padding: 26px 35px 26px 30px !important;
}

.userAvatarMore {
  background: linear-gradient(rgba(0, 0, 0, .7), rgba(0, 0, 0, .7)) !important;
  font-size: 15px !important;
  /* border-radius: 0 !important; */
}

.team-list .user-avatar:not([class*="sieze-"]) {
  width: 20px !important;
  height: 20px !important;
}

.manufacturerTag {
  margin-bottom: -16px !important;
}

.react-datepicker-wrapper {
  width: 100%;
}

.CartImage {
  width: 65px !important;
  height: 65px !important;
}

@media only screen and (max-width: 600px) {
  .CartImage {
    width: 35px !important;
    height: 35px !important;
  }
}

.orderTitle {
  height: 45px;
}

.orderFooter {
  padding: 10px;
  /* border-top: 1px solid #dee2e6 !important; */
  text-align: center !important;
  z-index: 10px !important;
  margin: 0 -10px !important;
  background-color: #072791 !important;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.tabAlign {
  width: 100% !important;
  display: inline-flex !important;
  align-items: flex-start !important;
  flex-direction: column !important;
  justify-content: flex-start !important;
  padding-top: 12px !important;
}

.filterBody:hover {
  background-color: #ffffff !important;
}

.myCartUserAvatar img {
  max-width: 100% !important;
  height: inherit !important;
}

.productImageStyle {
  width : 150px !important;
  height : 150px !important;
  border-radius: 0px !important;
}

@media only screen and (min-width:1400px) {
  /* .buyersMainContent {
    padding-left: 100px;
    padding-right: 100px;
  } */
}

.MUIDataTableFilter-title-422 .MuiButton-root {
  display: none;
}

.media {
  min-height: 75px;
}

.dashboardCardIcon {
  font-size: 40px !important;
}


.tableRoot {
  width: '100%' !important;
  margin-top: 15px !important;
  overflow-x: auto !important;
}

.tableMain {
  min-width: 700px !important ;
}

.tableMain1 {
  min-width: 400px !important ;
}

.editOrderIcon {
  font-size: 25px;
}

.page-heading {
  padding: 16px 26px !important;
}

.showQty {
  width: 70px !important;
  height: 70px !important;
}

.dashboardCardHead {
  font-size: 25px;
  font-weight: 100;
}

.dashboardCardText {
  font-size: 20px;
  font-weight: 200;
}

@media only screen and (min-width: 600px) {
  .widgetCard {
    min-height: 147px !important;
  }
} 

.EditorderPopup .MuiDialog-paperWidthSm {
  width: 400px !important;
  max-width: 400px !important;
}

.orderDetailsMainCol {
  padding: 0 35px 10px 35px !important;
}

.error {
  color : red;
  text-align: left;
}
.signInFooterend{
  display: flex !important;
  align-self: flex-end !important;
  justify-content: flex-end !important;
}

.cartQuantity {
  width: auto !important;
}

@media only screen and (min-width:420px) {
  .cartQuantity {
    width: 13% !important;
  }
}

.emptyCart { 
  display: block;
}

.datePickerStyle {
  padding:  0 !important;
  text-align: center;
  font-size: 15px;
  width: 80px !important;
  margin-top: 2px !important;
  width: 90px;
}

.datePickerStyle div{
  width: 90px;
}

.datePickerStyle div input{
  width: 90px;
  padding: 0;
}

.datePickerStyle div fieldset{
  width: 90px;
  border: none;
}

.datePickerStyle .MuiInput-underline:before {
  left: 0 !important;
  right: 0 !important;
  content: unset !important;
  bottom: 0 !important;
}

.datePickerStyle .MuiInput-underline:after {
  left: 0 !important;
  right: 0 !important;
  content: unset !important;
  bottom: 0 !important;
}

.order-details {
  position: fixed;
  top: 45%;
  right: 0;
  z-index: 1500;
  background-color: #fff;
  border-top-left-radius: .375rem;
  border-bottom-left-radius: .375rem;
  -o-box-shadow: 1px 1px 4px rgba(0,0,0,.5);
  box-shadow: 1px 1px 4px rgba(0,0,0,.5);
  /* color: #072791; */
}

#combo-box-demo {
  padding: 0px !important;
}

#percentOff {
  text-align-last: right;
}

#buy {
  text-align-last: right;
}

#get {
  text-align-last: right;
}

/* 
@media only screen and (min-width:1200px) {
  .jr-slick-slider-lt-thumb {
    height: 550px !important;
    width: 550px !important;
  }
}

@media only screen and (min-width:1025px) {
  .jr-slick-slider-lt-thumb {
    height: 597px !important;
    width: 597px !important;
  }
}

@media only screen and (min-width:1112px) {
  .jr-slick-slider-lt-thumb {
    height: 554px !important;
    width: 554px !important;
  }
}

@media only screen and (min-width:1009px) {
  .jr-slick-slider-lt-thumb {
    height: 461px !important;
    width: 461px !important;
  }
}

@media only screen and (min-width:76px) {
  .jr-slick-slider-lt-thumb {
    height: 374px !important;
    width: 374px !important;
  }
}

@media only screen and (min-width:414) {
  .jr-slick-slider-lt-thumb {
    height: 335px !important;
    width: 335px !important;
  }
}

@media only screen and (min-width:375) {
  .jr-slick-slider-lt-thumb {
    height: 335px !important;
    width: 335px !important;
  }
}
 */

/* @media only screen and (min-width:320px) {`
  .search-container {
    width: 97% !important;
  }
  .cartIcon{
      margin-right: 5px !important;
  }
}
@media only screen and (min-width:1265px) {
  .search-container {
    width: 290% !important;
  }
  .cartIcon{
      margin-right: 5px !important;
  }
}
@media only screen and (min-width:1220px) {
  .search-container {
    width: 290% !important;
  }
  .cartIcon{
      margin-right: 5px !important;
  }
}
@media only screen and (min-width:1200px) {
  .search-container {
    width: 288% !important;
  }
  .cartIcon{
      margin-right: 3px !important;
  }
}
@media only screen and (max-width:1112px) {
  .search-container {
    width: 287% !important;
  }
  .cartIcon{
      margin-right: 8px !important;
  }
  .rightIcon{
      margin-left: 367px !important;
  }
}
@media only screen and (max-width:1009px) {
  .search-container {
    width: 290% !important;
  }
  .cartIcon{
      margin-right: 10px !important;
  }
  .rightIcon{
      margin-left: 350px !important;
  }
} */

/* CArd CSS */
.cardHeading {
  font-size: medium !important;
  height: 5px !important;
  margin: 0 !important;
}

.cardMrp {
  font-size: small !important;
  color: rgb(118, 118, 118);
  min-height: "10px";
  margin-bottom: "10px";
  display: flex;
  justify-content: space-between;
}

.chemCombination {
  min-height: 30px !important;
  margin-bottom: 0px !important;
  font-size: small;
  font-weight: 400;
  color: rgb(24, 24, 24);
}

.companyName {
  color: rgb(65, 65, 65);
  font-size: small;
}

.NameBold {
  font-weight: 600;
  font-size: small;
}

/* CArd footer css*/
.price {
  font-size: 1.3rem !important;
  font-weight: 700 !important;
}

.priceFont {
  font-size: small !important;
  font-weight: 500;
  margin-left: 5px !important;
}

.orderQuantity {
  display: block;
  color: black !important;
  font-size: .87rem !important;
  font-weight: 400 !important;
  margin-top: 5px;
}

.chipBox {
  margin-bottom: 10px !important;
  background-color: rgb(6, 40, 146) !important;
  color: white !important;
  font-weight: 400 !important;
  font-size: 0.8125rem !important;
}

.cardBodyPadding {
    padding: 30px !important;
    /* width: 100% !important;
    margin: auto !important; */
}

.card-header-style {
  position: relative;
  display: inline-flex;
  padding: '0px';
}

.paddingBatch {
  padding: '17px 10px';
}

.navbarNotification {
  display: block;
}

@media only screen and (min-width: 456px) and (max-width: 486px) {
  .card-header-style {
    min-height: 428px !important;
    position: relative;
    display: inline-flex;
    padding: '0px';
  }
  .seller-card-min-height {
    min-height: 350px !important;
  }
  .navbarNotification {
  display: none;
}
}

@media only screen and (min-width: 436px) and (max-width: 456px) {
  .card-header-style {
    min-height: 428px !important;
    position: relative;
    display: inline-flex;
    padding: '0px';
  }
  .seller-card-min-height {
    min-height: 350px !important;
  }
  .navbarNotification {
  display: none;
}
}

@media only screen and (min-width: 400px) and (max-width: 436px) {
  .card-header-style {
    min-height: 398px !important;
    position: relative;
    display: inline-flex;
    padding: '0px';
  }
  .seller-card-min-height {
    min-height: 280px !important;
  }
  .navbarNotification {
  display: none;
}
}

@media only screen and (min-width: 380px) and (max-width: 399px) {
  .card-header-style {
    min-height: 360px !important;
  }
  .seller-card-min-height {
    min-height: 240px !important;
  }
  .cardBodyPadding {
    padding: 20px !important;
}
.cardHeading {
  font-size: medium !important;
  height: 5px !important;
  margin: 0 !important;
}
.companyName {
  color: rgb(65, 65, 65);
  font-size: small;
}
.navbarNotification {
  display: none;
}
}

@media only screen and (min-width: 220px) and (max-width: 380px) {
  .card-header-style {
    min-height: 350px !important;
  }
  .seller-card-min-height {
    min-height: 230px !important;
  }
  .navbarNotification {
  display: none;
} 
}

@media only screen and (max-width: 1205px) {
    .navbarNotification {
  display: none;
} 
}