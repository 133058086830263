/* *{
    border-collapse: separate; 
}
.btn {
    border: 2px solid black;
    background-color: white;
    color: black;
    padding: 10px 28px;
    font-size: 16px;
    border-radius: 20px;
    cursor: pointer;
    perspective: 1px;
    overflow: hidden;
  }
  
  /* Blue */
  /* .info {
    border-color: #072791;
    color: #072791;
  }
  
  .info:hover {
    background: #072791;
    color: white;
  } */ 
  .less2000{
    color:red;
  }
  .more2000{
    color:green;
  }