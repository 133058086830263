.BProfile .jr-profileon-thumb-htctrcrop{
    padding-bottom: 100% !important;
}
@media only screen and (min-width:200px) and (  max-width:575px) {
    .carousel-item.active div{
        margin-left: 5px !important;
    }
    .rcss{
        width:245px !important;
    }
    .carousel-item .rcss .rccs__card{
        width: auto !important;
        height: 152.873px !important;
    }
  }

  .carousel-control-next-icon, .carousel-control-prev-icon {
    width: 35px !important;
    /* height: 35px !important; */
    border-radius: 50% !important;
  }


  .itemImageProductDetail .carousel-control-next {
    width: 35px !important;
  }
  
  .itemImageProductDetail .carousel-control-prev {
    width: 4% !important;
  }