.card-image .grid-thumb-equal {
    padding-bottom: 100% !important;
}

.ellipsis {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}

.greyText{
    color: #6c757d;
}

h6{
    font-size: 13px;
}
.card-title{
    height: 51px !important;
    margin-bottom: -15px !important;
    color: black !important;
    font-weight: 500;
}