
.net-chart:hover .jr-card-thumb {
    width: 60px !important;
    height: 100% !important;
}
.net-chart {
    padding-left: 66px !important;
    padding-right: 7px !important;
    height: 77px !important;
    min-height: 50px !important;
    width: 135px !important;
}
.net-chart .jr-card-thumb {
    margin: 0;
    width: 30px !important;
    height: 30px !important;
}
.net-chart .jr-card-thumb+.jr-card-thumb{
    margin-left: -6px !important;
}
.NetChart{
    margin-left: -6px !important;
}


@media screen and (max-width: 1689px) {
    .infoCard {
     margin-left: -5px;
    }
  }

  @media screen and (max-width: 1495px) {
    .infoCard {
     margin-left: -10px;
    }
  }
  
  @media screen and (max-width: 1495px) {
    .infoCard {
     margin-left: -10px;
    }
  }

  @media screen and (max-width: 1408px) {
    .infoCard {
     margin-left: -20px;
    }
  }

  @media screen and (max-width: 1399px) {
    .infoCard {
     margin-left: 0px;
    }
  }
  
  @media screen and (max-width: 1080px) {
    .infoCard {
     display: inline;
    }
  }
  