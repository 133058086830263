
@media screen and (min-width: 415px){
.container1 {
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
}
}

/* 
@media screen and (min-width: 768px){
  .container1 {
    display: flex;
    flex-direction: column;
    flex: 1 1 0;
  }
  .3 {
    float: right ;
    margin-top: -10px;
  }
  }
   */
   