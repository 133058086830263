.marginLeftSerch {
  margin-left: -45px;
}

/* .popOver{
    padding: 50px;
}

.popOver:hover */

.manufacturersPopOver .popover {
  max-width: 770px !important;
  max-height: 470px;
  width: 770px;
}

.manufacturersPopOver .popover .popover-inner {
  background-color: #fff;
}

.horizontal {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 306px; /* account for borders */
  width: 767px;
  list-style-type:none;
  overflow-y: hidden;
}

.liCompanies {
  text-align: left;
  width: 325px;
  height: 45px;

  
}

.listItemName .MuiTypography-body1{
  font-size: 13px !important;
}


.moreButton{
  width: 20%;
  background-color: white;
  color: #072791;
  border-radius: 5px;
  border: none;
}

.h1Size{
  font-size: 14px;
}