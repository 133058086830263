@media only screen and (min-width:100px) {
  .app-notification-menu {
    width: 35px !important;
    height: auto !important;
  }
}

@media only screen and (min-width:693px) {
  .app-notification-menu {
    width: 90px !important;
    height: 37px !important;
  }
}

@media only screen and (max-width:480px) {
  .app-logoB img{
    position: absolute;
    margin-left: -30px;
  }
  
  .app-header-horizontal .app-toolbar {
    min-height: 120px !important;
  }
}

@media only screen and (max-width:480px) {


  .searchBox-container {
    margin-top: 10px !important;
  }
  .header-notifications .app-notification .dropdown-menu {
    right: 0 !important;
    left: -170px !important;
  }
  .header-notifications .app-notification .dropdown-menu:after {
    right: 0 !important;
    left: 190px !important;
  }
  .cart-submenu {
    padding-right: 0px !important;
  }
  .app-logo-img {
    height: 142px  !important;
    width: 100% !important;
  }
  .carousel {
    height: 148px !important;
  }
}

.app-logo-img {
  width: 100% !important;
}
.jr-list-link.buyerHeaderLink{
  display: block !important;
  text-decoration: none;
}


.SignInPopOver .popover {
  max-width: 400px !important;
  max-height: 425px;

}


.autofill{
  padding-top: 0px !important; 
}
.autoCompleteSearch .MuiAutocomplete-inputRoot{
  padding: 0px !important;
  border-radius: 0px !important;
  color: black !important;
  /* background: "white" !important; */
}

.searchTextField{
  background: "white" !important;
  /* color: "black" !important; */
}

/* .app-main-header .search-bar input{
      transition: all 0.2s ease-in-out;
      background-color: rgb(255, 255, 255);
      color: '';
} */